<template>
  <div class="fileObject-detail">
    <div class="content">
      <div class="d-flex justify-content-end">
        <CButton
          :color="theme === 'default' ? 'secondary' : ''"
          type="button"
          class="mr-3 zq--custom-button mb-3"
          @click="$router.go(-1)"
        >
          {{ $t('buttons.goBack') }}
        </CButton>
        <CButton
          v-if="isHtmlFile"
          :color="theme === 'default' ? 'secondary' : ''"
          type="button"
          class="mr-3 zq--custom-button"
          @click="goToEditHtml"
        >
          {{ $t('buttons.pageBuilder') }}
        </CButton>
        <ActionsForPreview
          :loading="loading"
          @edit="editFileObject"
          @delete="openModal"
        />
      </div>
      <div v-if="ready">
        <CRow>
          <CCol col="12">
            <CCard class="zq--wizard-card" v-if="ready">
              <CCardHeader @click="basicIsCollapsed = !basicIsCollapsed">
                <div class="d-flex justify-content-between">
                  <strong class="title">{{ texts.previewPage.title }}</strong>
                  <CLink class="card-header-action btn-minimize">
                    <ClCardArrow :is-collapsed="basicIsCollapsed" />
                  </CLink>
                </div>
              </CCardHeader>
              <CCollapse :show="basicIsCollapsed" :duration="400">
                <CCardBody>
                  <ZqFieldsView :texts="texts.previewPage" :formData="formData" :modelFields="modelFields" />
                </CCardBody>
              </CCollapse>
            </CCard>
          </CCol>
        </CRow>
        <ViewRules
            v-if="ruleModels.includes(model)"
            :entityId="entityId"
        />
        <ViewDependantOn
            v-if="formData.dependantOn && Object.keys(formData.dependantOn).length"
            :dependantOnData="formData.dependantOn"
        />
        <ViewScheduling
            v-if="schedulingData"
            :schedulingData="schedulingData"
        />
        <ViewTranslations
            v-if="modelFields.baseFields.translatableFields.length > 0"
            :entityData="formData"
            :entityId="formData.id"
        />
        <ViewRewards :entityId="entityId" v-if="rewardModels.includes(model)"/>
      </div>
      <PreviewSpiner v-else />

      <CRow>
        <CCol col="12">
          <CCard class="zq--wizard-card" v-if="ready">
            <CCardHeader @click="fileOverviewIsCollapsed = !fileOverviewIsCollapsed">
              <div class="d-flex justify-content-between">
                <strong class="title">File Preview</strong>
                <CLink class="card-header-action btn-minimize">
                  <ClCardArrow :is-collapsed="fileOverviewIsCollapsed" />
                </CLink>
              </div>
            </CCardHeader>
            <CCollapse v-if="!isImageFile && isFileReady" :show="fileOverviewIsCollapsed" :duration="400">
              <CCardBody>
                <codemirror v-model="fileString" :options="cmOptions" />
              </CCardBody>
            </CCollapse>
            <CCollapse v-if="isImageFile && isFileReady" :show="fileOverviewIsCollapsed" :duration="400">
              <CCardBody>
                <img :src="fileString"  alt="file" />
              </CCardBody>
            </CCollapse>
          </CCard>
        </CCol>
      </CRow>

    </div>
    <Modal
      :modalShow="deleteModal"
      :messageGeneral="texts.deleteMessage"
      :title="texts.deleteTitle"
      @doFunction="deleteFileObject"
      v-on:toggle-modal="deleteModal = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ActionsForPreview from '@/shared/components/ActionsForPreview';
import ZqFieldsView from '@/shared/components/ZqFieldsView';
import ClCardArrow from '@/shared/UI/ClCardArrow';
import { pageConfig } from '@/config';
import PreviewSpiner from '@/shared/UI/Spiner';
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import Modal from '@/shared/UI/Modal';
import { fileObjectsTexts } from '@/config/pageTexts/fileObjects.json';
import fields from '@/generated/ziqni/store/modules/fileObjects/fields';
import ViewTranslations from '@/shared/components/supportModels/translations/ViewTranslations';
import ViewRules from '@/shared/components/supportModels/rules/ViewRules';
import ViewDependantOn from '@/shared/components/supportModels/dependantOn/ViewDependantOn';
import ViewScheduling from '@/shared/components/supportModels/scheduling/ViewScheduling';
import ViewRewards from '@/shared/components/supportModels/rewards/ViewRewards';
import { codemirror } from 'vue-codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/javascript/javascript.js';
import 'codemirror/addon/display/autorefresh.js';
import 'codemirror/addon/fold/brace-fold.js';
import 'codemirror/addon/fold/foldgutter.css';
import 'codemirror/addon/fold/foldcode';
import 'codemirror/addon/fold/foldgutter';
import 'codemirror/addon/fold/brace-fold';
import 'codemirror/addon/fold/comment-fold';

export default {
  name: 'PreviewFile',
  components: {
    PreviewSpiner,
    ActionsForPreview,
    ZqFieldsView,
    Modal,
    ClCardArrow,
    ViewTranslations,
    ViewRules,
    ViewDependantOn,
    ViewScheduling,
    ViewRewards,
    codemirror,
  },
  data() {
    return {
      model: 'fileObject',
      entityId: this.$route.params.fileId,
      code: 'const A = 10',
      cmOptions: {
        tabSize: 4,
        styleActiveLine: false,
        readOnly: false,
        lineNumbers: true,
        styleSelectedText: false,
        line: true,
        foldGutter: true,
        lineWrapping: true,
        gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers'],
        hintOptions:{
          completeSingle: false
        },
        matchBrackets: true,
        showCursorWhenSelecting: true,
        extraKeys: { "Ctrl": "autocomplete" }
      },
      formData: {
        id: '',
        spaceName: '',
        created: '',
        customFields: {},
        tags: [],
        metadata: {},
        repositoryId: '',
        name: '',
        mimeType: '',
        path: '',
        parentFolderPath: '',
        uri: '',
        size: '',
        constraints: []
      },
      texts: {
        ...fileObjectsTexts,
      },
      basicIsCollapsed: true,
      fileOverviewIsCollapsed: false,
      inboxIsCollapsed: false,
      deleteModal: false,
      itemsPerPage: pageConfig.itemsPerPage,
      query: {},
      sortBy: pageConfig.sortBy,
      page: pageConfig.page,
      modelFields : {
        ...fields,
      },
      ready: false,
      schedulingData: null,
      rewardModels: ['achievement'],
      ruleModels: ['achievement'],
      previewFileObject: null,
      fileString: '',
      isImageFile: false,
      isHtmlFile: false,
      isFileReady: false,
    }
  },
  computed: {
    ...mapGetters('fileRepositories', ['fileRepository']),
    ...mapGetters('files', [
      'success',
      'message',
      'fileObjects',
      'loading',
      'fileObject',
      'innerFolders'
    ]),
    ...mapGetters('theme', ['theme']),
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions('files', [
      'handleGetFileObjects_item',
      'handleDeleteFileObjects',
      'handleDownloadFileObjects',
      'handleGetFileObjectsByQuery',
      'handleGetBreadcrumbs'
    ]),
    ...mapActions('fileRepositories', ['handleGetFileRepositories_item']),
    async initialize() {
      await this.handleGetFileRepositories_item([this.$route.params.id],1,0);
      await this.handleGetFileObjects_item({idArray: [this.$route.params.fileId]})
        .then((data) => {
          if (data[0].scheduling) {
            this.schedulingData = data[0].scheduling;
          }

          if (data[0].mimeType === 'text/html') {
            this.isHtmlFile = true;
          }

          this.previewFileObject = data[0];
          this.downloadPreviewFileObject();

          this.ready = true;
        });
    },
    async downloadPreviewFileObject() {
      const fileType = this.previewFileObject.mimeType.split('/')[0];

      if (this.previewFileObject && fileType === 'image') {
        this.fileString = this.previewFileObject.uri;
        this.isImageFile = true;
        this.isFileReady = true;
      } else {
        fetch(this.previewFileObject.uri)
          .then(res => res.blob())
          .then(async (blob) => {
            this.fileString = await new Response(blob).text();
            this.isFileReady = true;
          });
      }
    },
    goToEditHtml() {
      this.$router.push({
        name: 'WebBuilder',
        params: {
          fileId: this.$route.params.fileId,
          fileName: this.$route.params.fileName,
          id: this.$route.params.id,
        },
        query: {
          innerFolders: this.$router.currentRoute.query.innerFolders
        }
      })
    },
    editFileObject() {
      this.$router.push({
        name: 'EditFile',
        params: {
          id: this.$route.params.id,
          name: this.formData.name,
        },
        query: {
          innerFolders: this.$router.currentRoute.query.innerFolders
        }
      });
    },
    deleteFileObject() {
      this.deleteModal = false;
      this.handleDeleteFileObjects({idArray: [this.formData.id]})
        .then(() => {
          this.$router.push({
            name: 'PreviewFileRepository',
            params: {
              id: this.$route.params.id,
            },
          });
        });
    },
    openModal() {
      this.deleteModal = true;
    },
  },
  watch: {
    fileObject: {
      deep: true,
      handler: async function(val) {
        this.formData = val;

        let folderName = this.fileObject.parentFolderPath.replace('/', '');
        const idx = folderName.indexOf('/');
        if (idx !== -1) {
          const arr = folderName.split('/');
          folderName = arr[arr.length - 1];
        }

        let folderId = '';

        if (folderName) {
          const folder = await this.handleGetFileObjectsByQuery({
            idArray: [],
            queryRequest: {
              must: [
                {
                  queryField: 'repositoryId',
                  queryValues: [this.$router.currentRoute.params.id],
                },
                {
                  queryField: 'mimeType',
                  queryValues: ['inode/directory'],
                },
                {
                  queryField: 'name',
                  queryValues: [folderName],
                },
              ],
            }
          });

          folderId = folder[0].id;
        }

        const innerFolders = this.$router.currentRoute.query.innerFolders && this.$router.currentRoute.query.innerFolders.length
          ? [...JSON.parse(this.$router.currentRoute.query.innerFolders).reverse(), { id: folderId, name: folderName }]
          : [];

        routerBreadcrumbs(this.$router.currentRoute, {
          fileId: this.fileObject.id,
          fileName: this.fileObject.name,
          innerFolders: innerFolders,
          repositoryName: this.fileRepository.name,
          id: this.$router.currentRoute.params.id,
        });
      },
    },
  },

};
</script>
<style lang="scss">
fileObject-detail {
  height: 100%;
  flex: 2;
  .content {
    border-top: 1px solid var(--zq-content-border-color);
  }
  .form-content {
    height: calc(100% - 50px);
    background: var(--zq-sub-bg);
  }
  .lang-custom-button {
    width: 100%;
  }
}

.detail-label {
  @media (min-width: 420px) {
    max-width: 220px;
  }
}

.CodeMirror-sizer {
  margin-left: 40px !important;
}

.overview-label {
  font-style: italic;
  color: darkgray;
}

</style>
